<template>
  <div :class="`SendGiftContent ngf${id}`">
    <div dpadding border-bottom>
      <div strong>{{ product.name }}</div>
      <div v-gap="['calc(var(--mpadding)/2)', '', '', '']">
        <span strong v-size="`130%`">{{ product.price }}</span>
        <span v-gap="['', '', '', 'calc(var(--mpadding)/2)']">{{ $locale["credits"] }}</span>
      </div>
    </div>
    <div class="SendGiftContentGrid" :style="`--size:${itemSize}`">
      <div class="Item">
        <UserAvatar :user="$user" :size="itemSize" mauto />
      </div>
      <div class="Item Product">
        <img :src="product.image" noevents />
      </div>
      <div class="Item">
        <UserAvatar :user="creator" :size="itemSize" mauto />
      </div>
    </div>
    <div class="SendGiftContentMessage" border-top>
      <TextareaRich encode="true" class="Message" name="message" :placeholder="$locale['optional_message']" @update="(value) => (message = value)" />
    </div>
    <div class="SendGiftOptions" border-top dpadding>
      <Confirm :confirm="confirm" />
    </div>
  </div>
</template>

<script>
import Gifts from "./gifts.js";
export default {
  mixins: [Gifts],
  props: ["product", "creator", "modal"],
  data: function() {
    return {
      id: Go.uuid(),
      message: "",
      error: "",
      endpoint: "incentives/send_gift",
    };
  },
  computed: {
    itemSize: function() {
      if (this.$isMobile) return "80px";
      return "120px";
    },
    confirm: function() {
      return {
        loadingMessage: `${this.$locale["sending_gift_to"]} @${this.creator.user}...`,
        cancel: {
          label: this.$locale["cancel"],
          action: this.cancelSendGift,
        },
        accept: {
          label: this.$locale["send_gift"],
          action: this.sendGiftNow,
        },
      };
    },
  },
  methods: {
    cancelSendGift: function() {
      this.modal.close();
    },
    sendGiftNow: async function() {
      this.isLoading(true);

      try {
        this.payload = {
          type: Go.removeLastStringIf(this.product.type[0], "s"),
          product: this.product._id,
          message: this.message,
          creator: this.creator._id,
        };

        this.req = await this.api.post(`${this.endpoint}`, this.payload);
        this.res = this.req.data;
      } catch (error) {
        this.error = error;
      }

      if (this.error) {
        this.alert(Go.getErrorMessage(this.error));
        this.isLoading(false);
        return;
      }

      if (!this.res.success && this.res.reason === 1) {
        return this.modal.close(() => {
          this.myCreditsCard();
          this.alert(this.res);
          this.isLoading(false);
        });
      }

      if (!this.res.success) {
        this.isLoading(false);
        this.alert(this.res);
        return;
      }

      return this.modal.close(() => {
        this.isLoading(false);
        this.alert(this.res);
        this.$store.dispatch("getProfile");
      });
    },
  },
};
</script>

<style lang="scss">
.SendGiftContent {
  .SendGiftContentGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: $mpadding * 2;
    position: relative;
  }

  .SendGiftContentGrid .Item {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .SendGiftContentGrid .Item::before {
    content: "";
    position: absolute;
    background-color: rgba($color: #f8e5e5, $alpha: 1);
    @include transition(0.2s);
    @include sizeFixed(var(--size));
    z-index: -1;
    border-radius: 50%;
  }

  .SendGiftContentGrid:before {
    content: "";
    position: absolute;
    height: 4px;
    left: $mpadding * 4;
    right: $mpadding * 4;
    background-color: rgba($color: $primary_color, $alpha: 0.1);
    top: calc(50% - 2px);
    z-index: -1;
  }

  .SendGiftContentGrid img {
    max-width: $mpadding * 5;
    width: 100%;
    pointer-events: none;
  }

  .SendGiftContentGrid {
    padding: $mpadding * 2;
  }

  .Message [textarea] {
    padding: $mpadding;
    border-radius: 0;
    margin: 0;
    border: 0;
  }
}
</style>
